import LoadingSkeleton from 'components/elements/LoadingSkeleton';
import type { NavProps } from 'components/newListeningExperience/topnav/Nav';
import dynamic from 'next/dynamic';
import React, { FC } from 'react';

import { FeatureNameEnum, useFeatureVariant } from 'hooks/useFeatureFlags';
import { ListenableContent } from 'modules/sdk/lib';

const ListeningExperienceV2 = dynamic(
  () =>
    import(
      /* webpackChunkName: "components/listening/ListeningExperienceV2" */
      'modules/listening/components/ListeningExperienceV2'
    ),
  { ssr: false, loading: () => <LoadingSkeleton /> }
);

const ReaderBasedListeningExperience = dynamic(
  () =>
    import(
      /* webpackChunkName: "components/listening/ListeningExperienceV3" */
      'modules/listening/components/ReaderBasedListeningExperience'
    ).then(mod => mod.ReaderBasedListeningExperience),
  { ssr: false, loading: () => <LoadingSkeleton /> }
);

export type ListeningExperienceProps = {
  listenableContent: ListenableContent;
} & Pick<NavProps, 'onBackArrowClick'>;

export const ListeningExperience: FC<ListeningExperienceProps & { forceReaderBased?: boolean }> = ({
  listenableContent,
  onBackArrowClick,
  forceReaderBased
}) => {
  const isReaderBasedClassicModeEnabled = useFeatureVariant(FeatureNameEnum.WEB_APP_CLASSIC_READER_API);
  const isReaderBasedBookModeEnabled = useFeatureVariant(FeatureNameEnum.WEB_APP_BOOK_READER_API);

  if (isReaderBasedClassicModeEnabled.isLoading || isReaderBasedBookModeEnabled.isLoading) {
    return <LoadingSkeleton />;
  }

  const isReaderBased = listenableContent.isPDF() ? isReaderBasedBookModeEnabled.variant : isReaderBasedClassicModeEnabled.variant;

  if (isReaderBased || forceReaderBased) {
    return <ReaderBasedListeningExperience listenableContent={listenableContent} onBackArrowClick={onBackArrowClick} />;
  } else {
    return <ListeningExperienceV2 listenableContent={listenableContent} onBackArrowClick={onBackArrowClick} />;
  }
};
