import { Dialog } from '@headlessui/react';
import MoveIcon from 'components/newListeningExperience/player/icons/MoveIcon';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CloseIcon } from 'assets/icons/close-2';
import { useAuthStore } from 'modules/auth/store/authStore';
import { useExclusiveHotkeys } from 'modules/listening/hooks/useExclusiveHotkeys';
import { getRequiredStringEnv } from 'utils/safeEnvParsing';

import { useDebuggingToolsComponents } from '../hooks/useDebuggingToolComponents';

const canEnableDebuggingTools = (user: ReturnType<typeof useAuthStore.getState>['firebaseUser']) => {
  if (getRequiredStringEnv('NODE_ENV') === 'development') return true;
  if (!user) return false;
  if (!user.emailVerified) return false;
  const { email } = user;
  return email?.endsWith('@speechify.com') || email?.endsWith('@getspeechify.com');
};

export const DebuggingToolsPanel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const currentUser = useAuthStore(state => state.firebaseUser);

  useExclusiveHotkeys(
    {
      ctrlKey: true,
      shiftKey: true,
      code: 'KeyD'
    },
    () => {
      if (canEnableDebuggingTools(currentUser)) {
        setIsOpen(prev => !prev);
      }
    }
  );

  const [position, setPosition] = useState({ x: 100, y: 100 });
  const [size, setSize] = useState({ width: 500, height: 400 });
  const panelRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef(false);
  const isResizing = useRef(false);
  const dragOffset = useRef({ x: 0, y: 0 });

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging.current) {
        setPosition({
          x: e.clientX - dragOffset.current.x,
          y: e.clientY - dragOffset.current.y
        });
      } else if (isResizing.current) {
        setSize({
          width: Math.max(200, e.clientX - position.x),
          height: Math.max(150, e.clientY - position.y)
        });
      }
    };

    const handleMouseUp = () => {
      isDragging.current = false;
      isResizing.current = false;
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [position]);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    isDragging.current = true;
    dragOffset.current = {
      x: e.clientX - position.x,
      y: e.clientY - position.y
    };
  };

  const handleResizeMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    isResizing.current = true;
  };

  const visibleComponents = useDebuggingToolsComponents();

  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} onClose={() => {}} className="relative z-2000">
      <div className="fixed inset-0 pointer-events-none" aria-hidden="true" />
      <Dialog.Panel
        ref={panelRef}
        className="fixed overflow-hidden rounded-xl bg-white shadow-2xl z-2000"
        style={{
          left: `${position.x}px`,
          top: `${position.y}px`,
          width: `${size.width}px`,
          height: `${size.height}px`
        }}
      >
        <CloseIcon className="absolute right-4 top-1 fill-glass-600 hover:cursor-pointer" onClick={close} />

        <div className="flex flex-col items-start justify-start p-2 mt-2 h-full overflow-auto">
          <div className="mt-4 flex flex-col w-full">
            {visibleComponents.map((Component, index) => (
              <div className="bg-gray-50 px-2 pb-2 mb-4 rounded-100 border" key={index}>
                <Component />
              </div>
            ))}
          </div>
        </div>

        <div
          className="absolute top-1 left-1 cursor-grab hover:opacity-75 focus:opacity-50"
          onMouseDown={handleMouseDown}
          style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
        >
          <MoveIcon />
        </div>
        <div className="absolute right-0 bottom-0 w-4 h-4 cursor-nwse-resize" onMouseDown={handleResizeMouseDown} />
      </Dialog.Panel>
    </Dialog>
  );
};
