import { XIcon } from '@heroicons/react/outline';
import { intersection } from 'lodash';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useInterval } from 'hooks/useInterval';
import { useTranslation } from 'hooks/useTypedTranslation';
import { IntegrationService } from 'interfaces/integrations';
import { auth } from 'lib/speechify';
import { useAuthStore } from 'modules/auth/store/authStore';
import { useIntegrationStore } from 'modules/integration/stores/integrationStore';
import { integrationStoreActions } from 'modules/integration/stores/integrationStore/actions';
import { useImportStore } from 'modules/library/stores/importStore';
import { importStoreActions } from 'modules/library/stores/importStore/actions';

import { IntegrationIcons, IntegrationTitles } from './constants';
import { cleanupImportParams, getImportParams } from './helpers/importParams';

function IntegrationItem({ service }: { service: IntegrationService }) {
  const { t } = useTranslation('common');
  const showTooltip = useIntegrationStore(state => state.showTooltip?.[service]);
  const isModalOpen = useImportStore(s => s.isModalOpen);
  const Icon = IntegrationIcons[service];

  const handleTooltipClose = useCallback(() => {
    integrationStoreActions.hideTooltip(service);
  }, [service]);

  return (
    <div className="relative" onClick={handleTooltipClose}>
      <button
        className={twMerge(
          'w-full flex items-center gap-2 py-2 px-3.5 cursor-pointer rounded-[10px]',
          'hover:bg-glass-hovered-300 active:bg-glass-pressed-300 transition-colors duration-75'
        )}
        onClick={() => importStoreActions.openImportDialog({ source: service })}
      >
        <Icon className="w-5 h-5" />
        <span className="text-glass-700 font-medium font-ABCDiatype text-sm">{IntegrationTitles[service].name}</span>
      </button>
      {showTooltip && !isModalOpen && (
        <div
          className={twMerge(
            'rounded-md bg-glass-700 text-glass-0 shadow-500 py-3 pl-4 pr-6 box-border w-64',
            'absolute z-[100] left-full top-1/2 -translate-y-1/2 translate-x-[8px]',
            "after:content-[''] after:border after:border-transparent after:border-r-glass-700 after:border-r-[8px] after:border-y-[6px] after:block after:h-0 after:w-0",
            'after:absolute after:-left-[8px] after:top-1/2 after:-translate-y-1/2'
          )}
        >
          <XIcon className="absolute right-1 top-1 float-right block h-5 w-5 cursor-pointer text-glass-350" aria-hidden="true" onClick={handleTooltipClose} />
          <p className="text-base font-bold mb-1">✨ {t('New integration added')}</p>
          <p className="text-sm">{t('Click to import and listen to your files with Speechify.')}</p>
        </div>
      )}
    </div>
  );
}

function EnabledIntegrations({ children }: PropsWithChildren): JSX.Element {
  const auths = useIntegrationStore(state => state.auths);
  const user = useAuthStore(state => state.user);
  const userUid = auth.currentUser?.uid;

  const [isTabVisible, setIsTabVisible] = useState(!document.hidden);

  const enabledIntegrations = useMemo(() => {
    const services = Object.entries(auths)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, { authorized }]) => authorized)
      .map(([service]) => service);

    return intersection(Object.values(IntegrationService), services as IntegrationService[]);
  }, [auths]);

  useEffect(() => {
    // Need to check auth.currentUser?.uid as well because we need to grab user token when fetching integrations
    if (user?.uid && userUid) {
      integrationStoreActions.fetchAuths();
    }
  }, [user?.uid, userUid]);

  useEffect(() => {
    const visibilityChangeHandler = () => setIsTabVisible(!document.hidden);
    document.addEventListener('visibilitychange', visibilityChangeHandler);
    return () => document.removeEventListener('visibilitychange', visibilityChangeHandler);
  }, []);

  useInterval(
    () => {
      integrationStoreActions.fetchAuths();
    },
    isTabVisible ? 120_000 : null
  );

  useEffect(() => {
    const { service } = getImportParams();
    const isServiceSupported = enabledIntegrations.includes(service as IntegrationService);
    if (service) {
      const mappedService = isServiceSupported ? (service as IntegrationService) : null;

      importStoreActions.openImportDialog({ source: mappedService });
    }

    if (!isServiceSupported) {
      cleanupImportParams();
    }
  }, [enabledIntegrations]);

  if (!enabledIntegrations.length) return <>{children}</>;

  return (
    <div className="bg-glass-300 p-2 pt-1 mt-[-4px] mb-3 rounded-xl">
      {children}
      <div className="flex flex-col w-full gap-1">
        {enabledIntegrations.map(service => (
          <IntegrationItem service={service} key={service} />
        ))}
      </div>
    </div>
  );
}

export default React.memo(EnabledIntegrations);
