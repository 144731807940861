import LoadingSkeleton from 'components/elements/LoadingSkeleton';
import { InstantListening, isInstantListeningLoaded } from 'interfaces';
import { useCallback } from 'react';

import { ListeningExperience } from 'modules/listening/components/ListeningExperience';
import { mainPageStoreActions } from 'modules/mainPage/stores/mainPageStore/actions';
import { replaceUrlWithoutAnyEffect } from 'utils/navigation';

const InstantListeningRenderer = ({ instantListening }: { instantListening: InstantListening }) => {
  const onBackArrowClick = useCallback(() => {
    mainPageStoreActions.setInstantListening(null);
    replaceUrlWithoutAnyEffect('/');
  }, []);

  if (isInstantListeningLoaded(instantListening)) {
    return <ListeningExperience listenableContent={instantListening.details.listenableContent} onBackArrowClick={onBackArrowClick} />;
  }

  return <LoadingSkeleton />;
};

export default InstantListeningRenderer;
