import Modal from 'components/elements/Modal';
import { IUser } from 'interfaces';
import React from 'react';

import { ReferralUser, useReferralWidget } from 'lib/referrals';
import { useAuthStore } from 'modules/auth/store/authStore';

const getFirstAndLastName = (displayName: string) => {
  const [firstName, lastName] = displayName.split(' ').filter(x => x && x !== '');

  return {
    firstName,
    lastName
  };
};

export const getReferralUser = (user: IUser): ReferralUser => {
  const { firstName, lastName } = getFirstAndLastName(user.displayName ?? '');
  return {
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    id: user.uid,
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    accountId: user.uid,
    // @ts-expect-error TS(2322): Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    email: user.email,
    locale: navigator.language.replace('-', '_'),
    firstName,
    lastName
  };
};

export const SaasquatchWidget = ({ user }: { user: IUser }) => {
  useReferralWidget(getReferralUser(user));

  return <div className="squatchembed"></div>;
};

export const ReferralModal = ({ open, onClose }: { open: boolean; onClose?: () => void }) => {
  const user = useAuthStore(state => state.user);

  return (
    <Modal classNames="p-4" open={open} onClose={onClose} style={{ maxHeight: '800px', minHeight: '300px', minWidth: '620px', overflow: 'auto' }}>
      <SaasquatchWidget user={user!} />
    </Modal>
  );
};

export default ReferralModal;
