import { useAuthStore } from 'modules/auth/store/authStore';

import { DebuggingToolComponent } from '../types';

const CopyToClipboardIcon = () => (
  <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 52 52">
    <g>
      <path
        d="M17.4,11.6h17.3c0.9,0,1.6-0.7,1.6-1.6V6.8c0-2.6-2.1-4.8-4.7-4.8h-11c-2.6,0-4.7,2.2-4.7,4.8V10
      C15.8,10.9,16.5,11.6,17.4,11.6z"
      />
      <path
        d="M43.3,6h-1.6c-0.5,0-0.8,0.3-0.8,0.8V10c0,3.5-2.8,6.4-6.3,6.4H17.4c-3.5,0-6.3-2.9-6.3-6.4V6.8
      c0-0.5-0.3-0.8-0.8-0.8H8.7C6.1,6,4,8.2,4,10.8v34.4C4,47.8,6.1,50,8.7,50h34.6c2.6,0,4.7-2.2,4.7-4.8V10.8C48,8.2,45.9,6,43.3,6z"
      />
    </g>
  </svg>
);

const TextWithCopyButton = ({ text }: { text: string }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    // show toast that text is copied
    window.alert('Text copied to clipboard: ' + text);
  };
  return (
    <div className="flex items-center">
      <div className="text-glass-500">{text}</div>
      <button onClick={handleCopy} className="ml-2 stroke-[var(--colors-text-primary)]">
        <CopyToClipboardIcon />
      </button>
    </div>
  );
};

const AuthStateInspector = () => {
  const currentUser = useAuthStore(state => state.firebaseUser);
  return (
    <div className="mt-4 flex flex-col w-full">
      <div className="text-lg font-bold mb-4">Auth State</div>
      <div className="grid grid-cols-[minmax(0,1fr),auto] gap-x-2 gap-y-2 items-center [&>*:nth-child(even)]:justify-self-end">
        <div className="text-glass-500 truncate">Firebase User ID</div>
        <TextWithCopyButton text={currentUser?.uid ?? 'null'} />
        <div className="text-glass-500 truncate">Firebase User Email</div>
        <TextWithCopyButton text={currentUser?.email ?? 'null'} />
        <div className="text-glass-500 truncate">Firebase User Email Verified</div>
        <div>{currentUser?.emailVerified ? 'true' : 'false'}</div>
      </div>
    </div>
  );
};

export default {
  component: AuthStateInspector,
  condition: async () => useAuthStore.getState().firebaseUser !== null
} satisfies DebuggingToolComponent;
